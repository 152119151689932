/* eslint-disable no-unused-expressions */
import React, { useState } from 'react'
import MaskedInput from 'react-text-mask'
import { Button, Checkbox, Col, Form, Input, Row } from 'antd'
import PropTypes from 'prop-types'

import './AcessoLoginForm.css'

const { Password } = Input

const propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
}

const defaultProps = {
  loading: false
}

const areCredentialsSaved = () =>
  !!(
    window.localStorage.getItem('earetaguarda_usuario_cnpjoucpf') &&
    window.localStorage.getItem('earetaguarda_usuario_login')
  )

const removeCredentials = () => {
  window.localStorage.removeItem('earetaguarda_usuario_cnpjoucpf')
  window.localStorage.removeItem('earetaguarda_usuario_login')
}

const saveCredentials = ({ cnpjoucpf, login }) => {
  window.localStorage.setItem('earetaguarda_usuario_cnpjoucpf', cnpjoucpf)
  window.localStorage.setItem('earetaguarda_usuario_login', login)
}

const AcessoLoginForm = ({ loading, onSubmit }) => {
  const [areSavingCredentials, setAreSavingCredentials] =
    useState(areCredentialsSaved)
  const [cpfCnpjValue, setCpfCnpjValue] = useState('')

  const handleCpfCnpjChange = (event) => {
    setCpfCnpjValue(event.target.value)
  }

  const handleSubmit = (values) => {
    areSavingCredentials ? saveCredentials(values) : removeCredentials()
    onSubmit(values)
  }

  return (
    <Form
      className="acesso-login-form__container"
      layout="vertical"
      name="acesso-login-form"
      validateMessages={{
        required: 'Campo obrigatório.'
      }}
      onFinish={handleSubmit}
    >
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item
            initialValue={window.localStorage.getItem(
              'earetaguarda_usuario_cnpjoucpf'
            )}
            label="CNPJ ou CPF"
            name="cnpjoucpf"
            rules={[{ required: true }]}
          >
            <MaskedInput
              className="ant-input"
              mask={(input) => {
                const cleaned = input.replace(/[^\d]/g, '')
                return cleaned.length <= 11
                  ? [
                      /\d/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/
                    ]
                  : [
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '/',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/
                    ]
              }}
              value={cpfCnpjValue}
              onChange={handleCpfCnpjChange}
              guide={false}
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item
            initialValue={window.localStorage.getItem(
              'earetaguarda_usuario_login'
            )}
            label="Usuário"
            name="login"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Senha" name="senha" rules={[{ required: true }]}>
            <Password />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item>
            <Checkbox
              checked={areSavingCredentials}
              onChange={(event) => {
                setAreSavingCredentials(event.target.checked)
              }}
            >
              Lembrar de mim
            </Checkbox>
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item>
            <Button htmlType="submit" loading={loading} type="primary">
              Entrar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

AcessoLoginForm.propTypes = propTypes
AcessoLoginForm.defaultProps = defaultProps

export default AcessoLoginForm
