import {
  ApiOutlined,
  AuditOutlined,
  BookOutlined,
  ContainerOutlined,
  DesktopOutlined,
  HomeOutlined,
  LockOutlined,
  SettingOutlined,
  ShopOutlined
} from '@ant-design/icons'

const menu = [
  {
    icon: <HomeOutlined />,
    key: '/',
    label: 'Dashboard'
  },
  {
    icon: <DesktopOutlined />,
    key: '/cadastro',
    label: 'Cadastro',
    children: [
      {
        key: '/cadastro/adquirente-tef',
        label: 'Adquirente TEF'
      },
      {
        key: '/cadastro/aliquota',
        label: 'Alíquota'
      },
      {
        key: '/cadastro/bairro',
        label: 'Bairro'
      },
      {
        key: '/cadastro/bandeira-tef',
        label: 'Bandeira TEF'
      },
      {
        key: '/cadastro/cartao-consumo',
        label: 'Cartão de consumo'
      },
      {
        key: '/cadastro/cidade',
        label: 'Cidade'
      },
      {
        key: '/cadastro/cofins',
        label: 'COFINS'
      },
      {
        key: '/cadastro/disponibilidade',
        label: 'Disponibilidade de produtos'
      },
      {
        key: '/cadastro/disp-movel',
        label: 'Dispositivo móvel'
      },

      {
        key: '/cadastro/ficha',
        label: 'Ficha financeira'
      },
      {
        key: '/cadastro/forma-pgto',
        label: 'Forma de pagamento'
      },
      {
        key: '/cadastro/gatilho',
        label: 'Gatilho'
      },
      {
        key: '/cadastro/grupo-opcao',
        label: 'Grupo de opções'
      },
      {
        key: '/cadastro/grupo-produto',
        label: 'Grupo de produtos'
      },
      {
        key: '/cadastro/impressora-comanda',
        label: 'Impressora de comanda'
      },
      {
        key: '/cadastro/ecf',
        label: 'Impressora fiscal (ECF)'
      },
      {
        key: '/cadastro/maquina-pos',
        label: 'Máquina POS'
      },
      {
        key: '/cadastro/marca-sat',
        label: 'Marca SAT'
      },
      {
        key: '/cadastro/mesa',
        label: 'Mesa'
      },
      {
        key: '/cadastro/modalidade-tef',
        label: 'Modalidade TEF'
      },
      {
        key: '/cadastro/modelo-ecf',
        label: 'Modelo ECF'
      },

      {
        key: '/cadastro/motivo-cancela',
        label: 'Motivo de cancelamento'
      },
      {
        key: '/cadastro/motivo-transfere',
        label: 'Motivo de transferência'
      },
      {
        key: '/cadastro/opcao',
        label: 'Opção'
      },
      {
        key: '/cadastro/op-nao-fiscal',
        label: 'Operação não fiscal'
      },
      {
        key: '/cadastro/pdv',
        label: 'PDV'
      },
      {
        key: '/cadastro/pessoa',
        label: 'Pessoa'
      },
      {
        key: '/cadastro/pis',
        label: 'PIS'
      },
      {
        key: '/cadastro/praca',
        label: 'Praça'
      },
      {
        key: '/cadastro/produto',
        label: 'Produto'
      },
      {
        key: '/cadastro/regiao',
        label: 'Região'
      },
      {
        key: '/cadastro/sat',
        label: 'SAT'
      },
      {
        key: '/cadastro/setor-producao',
        label: 'Setor de produção'
      },
      {
        key: '/cadastro/tabela-preco',
        label: 'Tabela de preço'
      },
      {
        key: '/cadastro/tara-balanca',
        label: 'Tara de balança'
      },
      {
        key: '/cadastro/unidade',
        label: 'Unidade'
      }
    ]
  },
  {
    icon: <ContainerOutlined />,
    key: '/cfe',
    label: 'CF-e',
    children: [
      {
        key: '/cfe/extrair-cfe',
        label: 'Extrair e compactar CF-e'
      },
      {
        key: '/cfe/monitoramento-cfe',
        label: 'Monitoramento CF-e'
      }
    ]
  },
  {
    icon: <BookOutlined />,
    key: '/financeiro',
    label: 'Financeiro',
    children: [
      {
        key: '/financeiro/sped',
        label: 'SPED'
      },
      {
        key: '/financeiro/entrada-nota',
        label: 'Entrada de Nota'
      },
      {
        key: '/financeiro/conta-a-pagar',
        label: 'Conta a pagar'
      }
    ]
  },
  {
    icon: <LockOutlined />,
    key: '/controle-acesso',
    label: 'Controle de acesso'
  },
  {
    icon: <ShopOutlined />,
    key: '/estabelecimento',
    label: 'Estabelecimento'
  },
  {
    icon: <SettingOutlined />,
    key: '/acoes',
    label: 'Ações'
  },
  {
    icon: <ApiOutlined />,
    key: '/integracao',
    label: 'Integração',
    children: [
      {
        key: '/integracao/eacardapio',
        label: 'EACardápio'
      },
      {
        key: '/integracao/eadelivery',
        label: 'EADelivery'
      },
      {
        key: '/integracao/ifood',
        label: 'iFood'
      },
      {
        key: '/integracao/rappi',
        label: 'Rappi'
      },
      {
        key: '/integracao/varejofacil',
        label: 'Varejofacil'
      },
      {
        key: '/integracao/bysell',
        label: 'Bysell'
      }
    ]
  },
  {
    icon: <SettingOutlined />,
    key: '/parametros',
    label: 'Parâmetros de configuração'
  },
  {
    icon: <AuditOutlined />,
    key: '/relatorio',
    label: 'Relatório',
    children: [
      {
        key: '/relatorio/acompanhamento-prevendas',
        label: 'Acompanhamento de pré-vendas'
      },
      {
        key: '/relatorio/acompanhamento-prevendas-delivery',
        label: 'Acompanhamento de pré-vendas (delivery)'
      },
      {
        key: '/relatorio/agenda-encomendas',
        label: 'Agendamento de encomendas'
      },
      {
        key: '/relatorio/ajuste-estoque',
        label: 'Ajuste de estoque'
      },
      {
        key: '/relatorio/auditoria-vendas-por-praca',
        label: 'Auditoria de vendas por praça'
      },
      {
        key: '/relatorio/venda-por-produtos-auditoria',
        label: 'Auditoria de vendas por produtos'
      },
      {
        key: '/relatorio/auditoria-taxa-servico',
        label: 'Auditoria de taxa de serviço'
      },
      {
        key: '/relatorio/auditoria-vendas-varejofacil',
        label: 'Auditoria de vendas do Varejofacil'
      },
      {
        key: '/relatorio/cancelamento-prevendas',
        label: 'Cancelamento de pré-vendas'
      },
      {
        key: '/relatorio/clientes-que-nao-compraram',
        label: 'Clientes que não compraram'
      },
      {
        key: '/relatorio/consumo-clientes',
        label: 'Consumo de clientes'
      },
      {
        key: '/relatorio/crediario',
        label: 'Extrato de Crediário'
      },
      {
        key: '/relatorio/curva-abc',
        label: 'Curva ABC'
      },
      {
        key: '/relatorio/desempenho-atendente',
        label: 'Desempenho de atendente'
      },
      {
        key: '/relatorio/desempenho-entregador',
        label: 'Desempenho do entregador'
      },
      {
        key: '/relatorio/estoque',
        label: 'Estoque'
      },
      {
        key: '/relatorio/faturamento-diario-mensal',
        label: 'Faturamento diário/mensal'
      },
      {
        key: '/relatorio/flash-caixa',
        label: 'Flash de caixa'
      },
      {
        key: '/relatorio/informacoes-produtos',
        label: 'Informações de produtos'
      },
      {
        key: '/relatorio/listagem-clientes',
        label: 'Listagem de clientes'
      },
      {
        key: '/relatorio/venda-lucro-mensal',
        label: 'Lucro mensal'
      },
      {
        key: '/relatorio/venda-lucro-por-produto',
        label: 'Lucro por produto'
      },
      {
        key: '/relatorio/operacoes-nao-fiscais',
        label: 'Operações não fiscais'
      },
      {
        key: '/relatorio/produtos-por-cliente',
        label: 'Produtos por cliente'
      },
      {
        key: '/relatorio/produtos-vendidos-por-operador',
        label: 'Produtos vendidos por operador'
      },
      {
        key: '/relatorio/raio-x',
        label: 'Raio X'
      },
      {
        key: '/relatorio/ranking-clientes',
        label: 'Ranking de clientes'
      },
      {
        key: '/relatorio/vendas-eadelivery',
        label: 'Resumo de vendas do EADelivery'
      },
      {
        key: '/relatorio/saldo-devedor-crediario',
        label: 'Saldo devedor'
      },
      {
        key: '/relatorio/taxa-servico-por-data',
        label: 'Taxa de serviço por data'
      },
      {
        key: '/relatorio/taxa-servico-por-garcom',
        label: 'Taxa de serviço por garçom'
      },
      {
        key: '/relatorio/ticket-medio-cliente-por-pracas',
        label: 'Ticket médio cliente por praças'
      },
      {
        key: '/relatorio/troca-finalizadoras',
        label: 'Troca de finalizadoras'
      },
      {
        key: '/relatorio/vendas',
        label: 'Vendas'
      },
      {
        key: '/relatorio/vendas-canceladas',
        label: 'Vendas canceladas'
      },
      {
        key: '/relatorio/venda-por-categoria',
        label: 'Vendas por categoria'
      },
      {
        key: '/relatorio/vendas-por-cliente',
        label: 'Vendas por cliente'
      },
      {
        key: '/relatorio/venda-por-forma-pgto',
        label: 'Vendas por forma de pagamento'
      },
      {
        key: '/relatorio/venda-por-operador',
        label: 'Vendas por operador'
      },
      {
        key: '/relatorio/venda-por-produto',
        label: 'Vendas por produto'
      },
      {
        key: '/relatorio/vouchers',
        label: 'Vouchers'
      }
    ]
  }
]

export default menu
